import { useState } from "react"
import emailjs from "@emailjs/browser"
import "./Request.scss"

function Request() {
    const [name, setName] = useState();
    const [phone, setPhone] = useState();
    const [email, setEmail] = useState();
    const [subject, setSubject] = useState();
    const [message, setMessage] = useState();
  
    const handleSubmit = (e) => {
      e.preventDefault();
      let templateParams = {
        name,
        phone,
        email,
        subject,
        message
      }
      emailjs.send('Portfolio_Site', 'template_3a4pfds', templateParams, '3RZNs1V7ZaMJg2Q6Z').then(res => console.log(res.status))
      e.target.reset();
    }

    return (  
        <form className="contact-form" onSubmit={handleSubmit}>
            <h2 className="contact-form__heading">Request an Appointment</h2>
            <div className="contact-form__fields">
                <div className="contact-form__input-group">
                    <label htmlFor="name" className={`contact-form__label + ${name ? 'filled' : ''}`}>Name</label>
                    <input type="text" name="name" id="name" onChange={e => setName(e.target.value)}
                    className="contact-form__input" />
                </div>
                <div className="contact-form__input-group">
                    <label htmlFor="phone" className={`contact-form__label + ${phone ? 'filled' : ''}`}>Phone</label>
                    <input type="text" name="phone" id="phone" onChange={e => setPhone(e.target.value)} className="contact-form__input" />
                </div>
                <div className="contact-form__input-group contact-form__input-group--email">
                    <label htmlFor="email" className={`contact-form__label + contact-form__label--asterisk + ${email ? 'filled' : ''}`}>Email address</label>
                    <input type="text" name="email" id="email" onChange={e => setEmail(e.target.value)} className="contact-form__input" />
                </div>
                <div className="contact-form__input-group contact-form__input-group--message">
                    <label htmlFor="subject" className={`contact-form__label + ${subject ? 'filled' : ''}`}>Availability</label>
                    <textarea type="text" name="subject" id="subject" onChange={e => setSubject(e.target.value)} className="contact-form__input" />
                </div>
                <div className="contact-form__input-group contact-form__input-group--message">
                    <label htmlFor="message" className={`contact-form__label + ${message ? 'filled' : ''}`}>Additional Information</label>
                    <textarea type="text" name="message" id="message" onChange={e => setMessage(e.target.value)} className="contact-form__input" />
                </div>
                <button className="contact-form__submit">Submit</button>
            </div>
        </form>
    );
}

export default Request;