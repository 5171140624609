import React from "react";
import "./testimonials.scss";
import rickTravers from "../../Assets/Images/rick-travers.jpg";
// import examplePhoto from "../../Assets/Images/circle-box.svg";

function testimonials() {
  return (
    <div className="testimonials">
      <section className="testimonials__bigBox">
        <div className="testimonials__title">
          <h2>Testimonials</h2>
        </div>

        <div className="testimonials__client">
          <div className="testimonials__box">
            <img
              src={rickTravers}
              alt="profile"
              className="testimonials__image"
            />
            <div className="testimonials__client--indi">
              <h3>Ricky Travers</h3>
              <h6>Job Title</h6>

              <p className="testimonials__para">
              I first met Miguel when he spoke at our KOC meeting. I set a meeting and he came to my home the very next week. We spoke about all things they had to offer. He is very knowledgeable and also had an associate zoom with us to make sure I understood everything they had to offer. Eventually we set up an annuities account for retirement. I’m glad I dealt with Miguel as he is a man of GOD. He treated me like family and now we are friends. I highly recommend him for all your needs. 
              </p>
            </div>
          </div>
          </div>
          {/* <div className="testimonials__box">
            <img
              src={examplePhoto}
              alt="profile"
              className="testimonials__image"
            />
            <div className="testimonials__client--indi">
              <h3>Tito Puente</h3>
              <h6>Job Title</h6>
              <p className="testimonials__para">
                Raving reviews about how much MAC Risk Serivices has helped him
                and his company
              </p>
            </div>
          </div> */}
          {/* <div className="testimonials__box">
            <img
              src={examplePhoto}
              alt="profile"
              className="testimonials__image"
            />
            <div className="testimonials__client--indi__last testimonials__client--indi">
              <h3>WIllie Colon</h3>
              <h6>Job Title</h6>
              <p className="testimonials__para">
                Raving reviews about how much MAC Risk Serivices has helped him
                and his company
              </p>
            </div> */}
          {/* </div>
        </div> */}
      </section>
    </div>
  );
}

export default testimonials;
