import React from "react";
import "./services.scss";
import { NavLink, Routes, Route } from "react-router-dom"
import Councils from "../../pages/Councils/Councils"
import Request from "../../pages/Request/Request"
import Plans from "../../pages/Plans/Plans"

function services() {
  return (
    <section id="services" className="info">
      <h2 className="info__heading">Services</h2>
      <ul className="info__list">
        <li className="info__item"><NavLink to="/councils" className="info__link">Councils Served</NavLink></li>
        <li className="info__item"><NavLink to="/request" className="info__link">Request an Appointment</NavLink></li>
        <li className="info__item"><NavLink to="/plans" className="info__link">Plans and Benefits</NavLink></li>
      </ul>

      <Routes>
        <Route path="/councils" element={<Councils />} />
        <Route path="/request" element={<Request />} />
        <Route path="/plans" element={<Plans />} />
      </Routes>
    </section>
    
  );
}

export default services;
