import React, { useState } from "react";
import hamburger from "../../Assets/Icons/hamburger.svg";
import Hamburger from "../HamburgerModal/HamburgerModal";
import "./nav.scss";
import { useMediaQuery } from "react-responsive"
import { useEffect } from "react";
import kofc from "../../Assets/Images/kofc-assets/logo-blue-wtrmrk.png"

function Nav() {
  const [showHamModal, setShowHamModal] = useState(false);
  const isTablet = useMediaQuery({ query: '(min-width: 768px)'});

  const handleShowModal = () => {
    setShowHamModal(!showHamModal)
  }

  useEffect(() => {
    if(showHamModal && !isTablet) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
  }, [showHamModal, isTablet])

  if(isTablet) {
    return (
      <div className="nav">
        <section className="nav__bigBox">
          <div className="nav__top-container">
            <div className="nav__left-side">
              <img src={kofc} alt="Knights of Columbus logo" className="nav__kofc" />
              <h3 className="nav__title">Miguel Cruz - Knights of Columbus</h3>
            </div>
            {showHamModal ? <Hamburger showHamModal={showHamModal} setShowHamModal={setShowHamModal}/> : null}
            {showHamModal ? null : <img src={hamburger} alt="hamburger menu icon" onClick={handleShowModal} className="nav__hamburgerButton" />}
          </div>
        </section>
      </div>
    )
  } else {
    return(
      <div className="nav">
        <section className="nav__bigBox">
          <div className="nav__top-container">
          <div className="nav__left-side">
              <img src={kofc} alt="Knights of Columbus logo" className="nav__kofc" />
              <h3 className="nav__title">Miguel Cruz - Knights of Columbus</h3>
            </div>
            {showHamModal ? null : <img src={hamburger} alt="hamburger menu icon" onClick={handleShowModal} className="nav__hamburgerButton" />}
          </div>
          {showHamModal ? <Hamburger showHamModal={showHamModal} setShowHamModal={setShowHamModal}/> : null}
        </section>
      </div>
    )
  }  

}

export default Nav;
