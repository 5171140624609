import React from "react";
import miguelFront from "../../Assets/Images/miguel-front.jpg"
import "./hero.scss";

function hero() {
  return (
    <div className="hero">
      <div className="hero__box">
        <img src={miguelFront} alt="" className="hero__miguel" />
        <div className="hero__copy">
          <h2 className="hero__title">Miguel Cruz Field Agent</h2>
          <p className="hero__desc">
            Dedicated to helping you and your family find the right plan
          </p>
          <a href="#contact" className="hero__link">
            <button className="hero__button">Contact Us</button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default hero;
