import Council from "../../Components/Council/Council";
import councilsData from "../../data/councils.json"
import {v4 as uuid} from "uuid"
import "./Councils.scss"

function Councils() {

    return (
        <section className="councils">
            <h2 className="councils__heading">Councils Served</h2>
            <div className="councils__collective">
                {councilsData.map(singleCouncil => <Council key={uuid()} singleCouncil={singleCouncil}/>)}
            </div>
        </section>  
    );
}

export default Councils;