import "./Council.scss"
import { useEffect, useRef } from "react"
function Council({ singleCouncil }) {
    const imgRef = useRef();

    useEffect(() => {
        imgRef.current = singleCouncil.img
    }, [singleCouncil.img])
    return ( 
        <a href={singleCouncil.website} target="_blank" rel="noreferrer" className="council">
            <img src={singleCouncil.img} alt="" className="council__img" />
            <div className="council__copy">
                <h3 className="council__heading">{singleCouncil.name}</h3>
                <p className="council__number">{singleCouncil.council_number}</p>
                <p className="council__location">{singleCouncil.location}</p>
            </div>
        </a> 
    );
}

export default Council;