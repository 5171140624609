import "./Plans.scss"

function plans() {
    return (  
        <section className="plans">
            <h2 className="plans__heading">Most Popular Plans</h2>
            <div className="plans__information">
                <a href="https://www.kofc.org/en/what-we-do/insurance/permanent-life-insurance.html" className="plans__plan plan">
                    <h3 className="plan__heading">Permanent Life Insurance</h3>
                    <p className="plan__copy">Permanent life insurance is a great way to provide security for your family. It provides money to your family when you die, and builds cash value while you live. It’s guaranteed. It’s secure. It’s dynamic.</p>
                </a>
                <a href="https://www.kofc.org/en/what-we-do/insurance/term-life-insurance.html" className="plans__plan plan">
                    <h3 className="plan__heading">Term Life Insurance</h3>
                    <p className="plan__copy">Term life insurance provides affordable protection to your loved ones for temporary needs. It’s simple and straightforward.</p>
                </a>
                <a href="https://www.kofc.org/en/what-we-do/insurance/retirement-annuities.html" className="plans__plan plan">
                    <h3 className="plan__heading">Retirement Annuities</h3>
                    <p className="plan__copy">An annuity is a retirement savings product. You make contributions, and, after a specified amount of time, the annuity pays you a regular income stream.</p>
                </a>
                <a href="https://www.kofc.org/en/what-we-do/insurance/long-term-care-insurance.html" className="plans__plan plan">
                    <h3 className="plan__heading">Long Term Care</h3>
                    <p className="plan__copy">Long-term care insurance helps you pay for your extended medical needs in a nursing home facility or in the comfort of your own home.</p>
                </a>
            </div>
        </section>
    );
}

export default plans;